import React from "react";

export const WhyChooseUs = () => (
  <section className="why-us position-relative p-0 text-lg-dark">
    <img
      src="/images/castlepoint.jpg"
      alt="Castlepoint lighthouse, Wairarapa, New Zealand"
      className="bg-image"
    />
    <div className="container">
      <div className="row">
        <div className="ml-auto col-lg-6">
          <div className="why-us__content">
            <h2 className="h2 mb-4">Why choose Sixvees</h2>
            <ul className="fa-ul pl-3 pt-3 ml-2 mb-4">
              <li className="d-flex align-items-center mb-3">
                <span className="fa-li">
                  <i className="fas fa-check-circle"></i>
                </span>
                We speak your language.
              </li>
              <li className="d-flex align-items-center mb-3">
                <span className="fa-li">
                  <i className="fas fa-check-circle"></i>
                </span>
                We listen.
              </li>
              <li className="d-flex align-items-center mb-3">
                <span className="fa-li">
                  <i className="fas fa-check-circle"></i>
                </span>
                We are experts.
              </li>
              <li className="d-flex align-items-center mb-3">
                <span className="fa-li">
                  <i className="fas fa-check-circle"></i>
                </span>
                We make life easy for you!
              </li>
            </ul>
            <a href="/how-to-get-online" className="btn btn-outline-info mt-3">
              View packages
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhyChooseUs;
