import React from "react";
import { Link } from "gatsby";

export const WhyBuildAWebsite = () => (
  <section className=" text-dark pt-0 dark-strip-top">
    <div className="container">
      <h2
        className="mb-5 text-white-stroke heading-sm aos-init aos-animate"
        data-aos="fade-right"
      >
        Why build a<br />
        website?
      </h2>
      <div className="row py-lg-5">
        <div className="col-lg-6">
          <img
            src="/images/Group-27.png"
            alt="computer"
            className="img-fluid mb-5 mb-lg-0 pr-lg-3"
          />
        </div>
        <div className="col-lg-6 d-flex align-items-center">
          <div>
            <p>
              The first thing a potential customer will do is Google you, if you
              aren’t online you could be missing out on loads of potential
              sales.
            </p>
            <p>
              Already have your business online? Your customers will be judging
              your business by the look and feel of your website, it’s just like
              judging a book by the cover. If your website is outdated then
              you’re losing credibility with your customers.
            </p>
            <p>
              Let Sixvees build you a website that will make a positive first
              impression, and put yourself a step ahead of your competition.
            </p>
            <Link
              to="/why-have-a-website/"
              className="btn btn-outline-info mt-3"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhyBuildAWebsite;
