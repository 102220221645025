import * as React from "react";
import Layout from "../components/Layout";
import WhyChooseUs from "../components/WhyChooseUs";
import WhyBuildAWebsite from "../components/WhyBuildAWebsite";
import { Link } from "gatsby";
import Packages from "../components/Packages";
import Testimonials from "../components/Testimonials";
import CallToAction from "../components/CallToAction";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Sixvees | Be Seen. Build a Website.</title>
        <meta
          name="description"
          content="Let Sixvees build you a website that will make a positive first impression, and put yourself a step ahead of your competition."
        ></meta>
      </Helmet>
      <main>
        <div className="sr-only">
          <div className="page-header sr-only">
            <h1>About us</h1>
          </div>
        </div>

        <section className="primary-content">
          <div className="primary-content__content">
            <div className="container">
              <div className="text-center">
                <img
                  src="/images/banner-logo.png"
                  alt="Sixvees banner logo"
                  className="img-fluid mx-auto mb-5 d-block"
                />
                <Link
                  href="/get-in-touch"
                  className="btn btn-outline-light mx-3 mt-3"
                >
                  Contact us
                </Link>
                <Link
                  href="/how-to-get-online"
                  className="btn btn-outline-light mx-3 mt-3"
                >
                  View packages
                </Link>
              </div>
            </div>
          </div>
          <div className="icon-scroll" style={{ opacity: 1 }} />
        </section>

        <WhyBuildAWebsite />
        <Testimonials rightAlignHeader />
        <Packages />
        <WhyChooseUs />
        <CallToAction
          text="No sure yet? Meet with us for free coffee and advice.
"
        />
      </main>
    </Layout>
  );
};

export default IndexPage;
